import React from 'react';
import classNames from 'classnames';
import s from './BuyNowButton.st.css';
import * as productPageButtonsContainerStyles from '../ProductPageButtonsContainer.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {Button} from 'wix-ui-tpa/Button';

export interface BuyNowButtonProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  productInStock: boolean;
}

export enum DataHook {
  buyNowButton = 'buy-now-button',
}

@withGlobalProps
@withTranslations('globals.texts')
export class BuyNowButton extends React.Component<BuyNowButtonProps> {
  public BuyNowButtonRef = React.createRef<HTMLButtonElement>();

  public componentDidUpdate(prevProps: Readonly<BuyNowButtonProps>, _prevState: Readonly<{}>, _snapshot?: any): void {
    if (prevProps.globals.isModalOpened && prevProps.globals.isModalClosed !== this.props.globals.isModalClosed) {
      this.onButtonFocus();
    }
  }

  public onButtonFocus = () => {
    this.BuyNowButtonRef.current.focus();
  };

  public render() {
    const {
      t,
      productInStock,
      globals: {
        product,
        selectedVariant,
        shouldShowWishlistButton,
        shouldShowAddToCartButton,
        handleBuyNow,
        accessibilityEnabled,
      },
    } = this.props;
    const {price} = selectedVariant || product;

    const disabled = !productInStock || price <= 0;
    let caption = t('BUY_NOW_BUTTON');

    if (disabled) {
      caption = price > 0 || !productInStock ? t('PRODUCT_OUT_OF_STOCK_BUTTON') : t('ADD_TO_CART_BUTTON_PRICE_ZERO');
    }

    const buttonClasses = classNames(s.buyNowButton, {
      [productPageButtonsContainerStyles.primaryButton]: shouldShowWishlistButton && !shouldShowAddToCartButton,
    });

    return (
      <Button
        ref={this.BuyNowButtonRef}
        onClick={() => handleBuyNow(accessibilityEnabled)}
        disabled={disabled}
        data-hook={DataHook.buyNowButton}
        className={buttonClasses}
        fullWidth={!shouldShowWishlistButton || shouldShowAddToCartButton}>
        {caption}
      </Button>
    );
  }
}
